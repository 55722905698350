<template>
  <div class="row mb-4 pb-4">
    <div class="col">
      <router-link :to="{ name: 'EfficiencyObjective1'}" class="od_nav_sub">
        Objetivo 1
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'EfficiencyObjective2'}" class="od_nav_sub">
        Objetivo 2
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'EfficiencyObjective4'}" class="od_nav_sub">
        Objetivo 4
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'EfficiencyObjective9'}" class="od_nav_sub">
        Objetivo 9
      </router-link>
    </div>
  </div>
</template>