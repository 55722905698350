<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {ref, computed} from "vue";
import {useStore} from "vuex";
import SubNav from "@/components/SubNavEfficiency";

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props : ['country'],
  setup(props){
    const store = useStore();
    const items = computed( () => store.getters.getCountryIndices(props.country));
    const indices = store.getters.getIndicesFromAxis(1, items);
    const avaliableIndices = indices.filter(d => d.value != null );
    const unavaliableIndices = indices.filter(d => d.value == null);

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      format : store.getters.formatNumber,
      show : ref(true),
      indices,
      avaliableIndices,
      unavaliableIndices,
      items
    }
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components: {
    SubNav
  },
};
</script>



<template>
  <div>
    <section>
      <h1 class="mb-4 pb-4">
        <strong>Eje 1.</strong> Eficiencia del gasto en VIH en
        <strong>{{ country.name }}</strong>
      </h1>

      <!-- indicadores disponibles-->
      <h3>
        {{ avaliableIndices.length }} indicadores disponibles de
        {{ indices.length }} posibles en el eje 1
      </h3>
      <div class="mb-3">
        <span
          style="
            display: inline-block;
            height: 15px;
            width: 100%;
            background: #f2f2f0;
          ">
          <span
            :style="{
              display: 'inline-block',
              width: `${(avaliableIndices.length * 100) / (indices.length)}%`,
              height: '15px',
              background: '#e9b03b',
            }"></span>
        </span>
      </div>
      <p class="mb-0">
        <span
          :style="{
            display: 'inline-block',
            height: '10px',
            background: '#e9b03b',
            width: '10px',
            margin: '0 5px 0 0',
          }"></span
        >Indicadores disponibles
      </p>
      <p class="mb-4 pb-4">
        <span class="im_stacked_amount"
          >{{
            format(
              (avaliableIndices.length * 100) / (indices.length)
            )
          }}%</span>
      </p>

      <p class="mb-4 pb-4">Medir la eficiencia del gasto en VIH permite conocer si los recursos públicos invertidos por los gobiernos producen los resultados esperados o si es necesario redirigir estos gastos. Un gasto eficiente es aquel en el cual los recursos públicos están siendo utilizados en la producción de resultados. </p>

      <sub-nav />

      <router-view :country="country" :indices="avaliableIndices" :items="items" />
    </section>

    <div>
      <section class="od_noindex">
        <h3 class="mb-3"><strong>{{unavaliableIndices.length}} indicadores no dispobibles</strong> en el eje 1 en {{country.name}}</h3>
        <button v-on:click="show = !show" class="od_btn_noindex mb-4">
        {{show ? 'Ocultar' : 'Mostrar'}} indicadores no disponibles
      </button>
      <ol v-if="show" class="od_list_noindex">
        <li v-for="el in unavaliableIndices" :key="el">{{el.name}}</li>
      </ol>
      </section>
    </div>
  </div>
</template>